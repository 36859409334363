import { type } from "@testing-library/user-event/dist/type";

export const ROWS_PER_PAGE = 500;

export const SENSORS = [
  {
    value: "Camera",
    ko: "Camera",
    name: "cam_cnt",
    col: "camCnt",
  },
  {
    value: "Radar",
    ko: "Radar",
    name: "rad_cnt",
    col: "radCnt",
  },
  {
    value: "LiDAR",
    ko: "LiDAR",
    name: "lid_cnt",
    col: "lidCnt",
  },
  {
    value: "IMU",
    ko: "IMU",
    name: "imu_cnt",
    col: "imuCnt",
  },
  {
    value: "GPS",
    ko: "GPS",
    name: "gps_cnt",
    col: "gpsCnt",
  },
];

export const ORIGS = [
  {
    value: "Camera",
    ko: "Camera",
    name: "cam_cnt",
    col: "camCnt",
  },
  {
    value: "Radar",
    ko: "Radar",
    name: "rad_cnt",
    col: "radCnt",
  },
];

export const CONDITIONS = [
  {
    value: "snow",
    ko: "눈",
    icon_b: "IC_snow_b",
    icon: "IC_snow_s",
    name: "snow_cnt",
    col: "snowCnt",
  },
  {
    value: "rain",
    ko: "비",
    icon_b: "IC_rain_b",
    icon: "IC_rain_s",
    name: "rain_cnt",
    col: "rainCnt",
  },
  {
    value: "fog",
    ko: "안개",
    icon_b: "IC_fog_b",
    icon: "IC_fog_s",
    name: "fog_cnt",
    col: "fogCnt",
  },
  {
    value: "light",
    ko: "조명",
    icon_b: "IC_light_b",
    icon: "IC_light_s",
    name: "light_cnt",
    col: "lightCnt",
  },
  {
    value: "backlight",
    ko: "역광",
    icon_b: "IC_backlight_b",
    icon: "IC_backlight_s",
    name: "backlight_cnt",
    col: "backlightCnt",
  },
  {
    value: "night",
    ko: "밤",
    icon_b: "IC_night_b",
    icon: "IC_night_s",
    name: "night_cnt",
    col: "nightCnt",
  },
  {
    value: "smoke",
    ko: "연기",
    icon_b: "IC_smoke_b",
    icon: "IC_smoke_s",
    name: "smoke_cnt",
    col: "smokeCnt",
  },
];

export const CAM_SEG_CONDITIONS = [
  {
    value: "snow",
    ko: "눈",
    icon_b: "IC_snow_b",
    icon: "IC_snow_s",
    name: "seg_snow_cnt",
    col: "segSnowCnt",
  },
  {
    value: "rain",
    ko: "비",
    icon_b: "IC_rain_b",
    icon: "IC_rain_s",
    name: "seg_rain_cnt",
    col: "segRainCnt",
  },
  {
    value: "fog",
    ko: "안개",
    icon_b: "IC_fog_b",
    icon: "IC_fog_s",
    name: "seg_fog_cnt",
    col: "segFogCnt",
  },
  {
    value: "light",
    ko: "조명",
    icon_b: "IC_light_b",
    icon: "IC_light_s",
    name: "seg_light_cnt",
    col: "segLightCnt",
  },
  {
    value: "backlight",
    ko: "역광",
    icon_b: "IC_backlight_b",
    icon: "IC_backlight_s",
    name: "seg_backlight_cnt",
    col: "segBacklightCnt",
  },
  {
    value: "night",
    ko: "밤",
    icon_b: "IC_night_b",
    icon: "IC_night_s",
    name: "seg_night_cnt",
    col: "segNightCnt",
  },
  {
    value: "smoke",
    ko: "연기",
    icon_b: "IC_smoke_b",
    icon: "IC_smoke_s",
    name: "seg_smoke_cnt",
    col: "segSmokeCnt",
  },
];

export const CAM_DET_CONDITIONS = [
  {
    value: "snow",
    ko: "눈",
    icon_b: "IC_snow_b",
    icon: "IC_snow_s",
    name: "det_snow_cnt",
    col: "detSnowCnt",
  },
  {
    value: "rain",
    ko: "비",
    icon_b: "IC_rain_b",
    icon: "IC_rain_s",
    name: "det_rain_cnt",
    col: "detRainCnt",
  },
  {
    value: "fog",
    ko: "안개",
    icon_b: "IC_fog_b",
    icon: "IC_fog_s",
    name: "det_fog_cnt",
    col: "detFogCnt",
  },
  {
    value: "light",
    ko: "조명",
    icon_b: "IC_light_b",
    icon: "IC_light_s",
    name: "det_light_cnt",
    col: "detLightCnt",
  },
  {
    value: "backlight",
    ko: "역광",
    icon_b: "IC_backlight_b",
    icon: "IC_backlight_s",
    name: "det_backlight_cnt",
    col: "detBacklightCnt",
  },
  {
    value: "night",
    ko: "밤",
    icon_b: "IC_night_b",
    icon: "IC_night_s",
    name: "det_night_cnt",
    col: "detNightCnt",
  },
  {
    value: "smoke",
    ko: "연기",
    icon_b: "IC_smoke_b",
    icon: "IC_smoke_s",
    name: "det_smoke_cnt",
    col: "detSmokeCnt",
  },
];

export const LRN_CONDITIONS = [
  {
    value: "snow",
    ko: "눈",
    icon_b: "IC_snow_b",
    icon: "IC_snow_s",
    name: "lrn_snow_cnt",
    col: "lrnSnowCnt",
  },
  {
    value: "rain",
    ko: "비",
    icon_b: "IC_rain_b",
    icon: "IC_rain_s",
    name: "lrn_rain_cnt",
    col: "lrnRainCnt",
  },
  {
    value: "fog",
    ko: "안개",
    icon_b: "IC_fog_b",
    icon: "IC_fog_s",
    name: "lrn_fog_cnt",
    col: "lrnFogCnt",
  },
  {
    value: "light",
    ko: "조명",
    icon_b: "IC_light_b",
    icon: "IC_light_s",
    name: "lrn_light_cnt",
    col: "lrnLightCnt",
  },
  {
    value: "backlight",
    ko: "역광",
    icon_b: "IC_backlight_b",
    icon: "IC_backlight_s",
    name: "lrn_backlight_cnt",
    col: "lrnBacklightCnt",
  },
  {
    value: "night",
    ko: "밤",
    icon_b: "IC_night_b",
    icon: "IC_night_s",
    name: "lrn_night_cnt",
    col: "lrnNightCnt",
  },
  {
    value: "smoke",
    ko: "연기",
    icon_b: "IC_smoke_b",
    icon: "IC_smoke_s",
    name: "lrn_smoke_cnt",
    col: "lrnSmokeCnt",
  },
];

export const REF_VIDEO_COL = [
  { name: "sensor_name", ko: "센서번호" },
  { name: "video_crt_t", ko: "참고 영상 생성 시간" },
];

// work tab (cam seg)
export const GT_TAB_CAMSEG_WORK = [
  {
    value: "1",
    ko: "전체",
  },
  {
    value: "4",
    ko: "작업대기",
  },
  {
    value: "5",
    ko: "작업중",
  },
  {
    value: "6",
    ko: "검수대기",
  },
  {
    value: "7",
    ko: "검수반려",
  },
  {
    value: "8",
    ko: "검수통과",
  },
  {
    value: "10",
    ko: "작업회수",
  },
];

// work tab (cam det)
export const GT_TAB_CAMDET_WORK = [
  {
    value: "1",
    ko: "전체",
  },
  {
    value: "4",
    ko: "작업대기",
  },
  {
    value: "5",
    ko: "작업중",
  },
  {
    value: "6",
    ko: "검수대기",
  },
  {
    value: "7",
    ko: "검수반려",
  },
  {
    value: "8",
    ko: "검수통과",
  },
  {
    value: "10",
    ko: "작업회수",
  },
];

// work tab (rad seg)
export const GT_TAB_RADSEG_WORK = [
  {
    value: "1",
    ko: "전체",
  },
  {
    value: "4",
    ko: "작업대기",
  },
  {
    value: "5",
    ko: "작업중",
  },
  {
    value: "6",
    ko: "검수대기",
  },
  {
    value: "7",
    ko: "검수반려",
  },
  {
    value: "8",
    ko: "검수통과",
  },
  {
    value: "10",
    ko: "작업회수",
  },
];

// check tab (cam seg)
export const GT_TAB_CAMSEG_CHECK = [
  {
    value: "1",
    ko: "전체",
    name: "check_cam_seg_tab1_cnt",
    col: "checkCamSegTab1Cnt",
  },
  {
    value: "2",
    ko: "배분대기",
    name: "check_cam_seg_tab2_cnt",
    col: "checkCamSegTab2Cnt",
  },
  {
    value: "4",
    ko: "작업대기",
    name: "check_cam_seg_tab4_cnt",
    col: "checkCamSegTab4Cnt",
  },
  {
    value: "5",
    ko: "작업중",
    name: "check_cam_seg_tab5_cnt",
    col: "checkCamSegTab5Cnt",
  },
  {
    value: "6",
    ko: "검수대기",
    type: "strong",
    name: "check_cam_seg_tab6_cnt",
    col: "checkCamSegTab6Cnt",
  },
  {
    value: "7",
    ko: "검수반려",
    name: "check_cam_seg_tab7_cnt",
    col: "checkCamSegTab7Cnt",
  },
  {
    value: "8",
    ko: "검수통과",
    name: "check_cam_seg_tab8_cnt",
    col: "checkCamSegTab8Cnt",
  },
  {
    value: "10",
    ko: "작업회수",
    name: "check_cam_seg_tab10_cnt",
    col: "checkCamSegTab10Cnt",
  },
  {
    value: "11",
    ko: "검수자 변경",
    name: "check_cam_seg_tab11_cnt",
    col: "checkCamSegTab11Cnt",
  },
];

// check tab (cam det)
export const GT_TAB_CAMDET_CHECK = [
  {
    value: "1",
    ko: "전체",
    name: "check_cam_det_tab1_cnt",
    col: "checkDetTab1Cnt",
  },
  {
    value: "2",
    ko: "배분대기",
    name: "check_cam_det_tab2_cnt",
    col: "checkDetTab2Cnt",
  },
  {
    value: "4",
    ko: "작업대기",
    name: "check_cam_det_tab4_cnt",
    col: "checkDetTab4Cnt",
  },
  {
    value: "5",
    ko: "작업중",
    name: "check_cam_det_tab5_cnt",
    col: "checkDetTab5Cnt",
  },
  {
    value: "6",
    ko: "검수대기",
    type: "strong",
    name: "check_cam_det_tab6_cnt",
    col: "checkDetTab6Cnt",
  },
  {
    value: "7",
    ko: "검수반려",
    name: "check_cam_det_tab7_cnt",
    col: "checkDetTab7Cnt",
  },
  {
    value: "8",
    ko: "검수통과",
    name: "check_cam_det_tab8_cnt",
    col: "checkDetTab8Cnt",
  },
  {
    value: "10",
    ko: "작업회수",
    name: "check_cam_det_tab10_cnt",
    col: "checkDetTab10Cnt",
  },
  {
    value: "11",
    ko: "검수자 변경",
    name: "check_cam_det_tab11_cnt",
    col: "checkDetTab11Cnt",
  },
];

// check tab (rad seg)
export const GT_TAB_RADSEG_CHECK = [
  {
    value: "1",
    ko: "전체",
    name: "check_rad_seg_tab1_cnt",
    col: "checkRadSegTab1Cnt",
  },
  {
    value: "2",
    ko: "배분대기",
    name: "check_rad_seg_tab2_cnt",
    col: "checkRadSegTab2Cnt",
  },
  {
    value: "4",
    ko: "작업대기",
    name: "check_rad_seg_tab4_cnt",
    col: "checkRadSegTab4Cnt",
  },
  {
    value: "5",
    ko: "작업중",
    name: "check_rad_seg_tab5_cnt",
    col: "checkRadSegTab5Cnt",
  },
  {
    value: "6",
    ko: "검수대기",
    type: "strong",
    name: "check_rad_seg_tab6_cnt",
    col: "checkRadSegTab6Cnt",
  },
  {
    value: "7",
    ko: "검수반려",
    name: "check_rad_seg_tab7_cnt",
    col: "checkRadSegTab7Cnt",
  },
  {
    value: "8",
    ko: "검수통과",
    name: "check_rad_seg_tab8_cnt",
    col: "checkRadSegTab8Cnt",
  },
  {
    value: "10",
    ko: "작업회수",
    name: "check_rad_seg_tab10_cnt",
    col: "checkRadSegTab10Cnt",
  },
  {
    value: "11",
    ko: "검수자 변경",
    name: "check_rad_seg_tab11_cnt",
    col: "checkRadSegTab11Cnt",
  },
];

// data status code
export const DATA_STATUS_CODE = [
  { value: "1", ko: "배분대기" },
  // { value: '2', ko: '배분' },
  { value: "4", ko: "작업대기" },
  { value: "5", ko: "작업시작" },
  { value: "6", ko: "라벨업로드완료" },
  { value: "7", ko: "에러검사반려" },
  { value: "8", ko: "에러검사통과" },
  { value: "9", ko: "검수대기" },
  { value: "10", ko: "검수반려" },
  { value: "11", ko: "검수통과" },
  { value: "12", ko: "학습반영" },
];

export const GUIDE_TAB = [
  { ko: "데이터 셋 가이드", value: "1" },
  { ko: "작업 셋 가이드", value: "2" },
  { ko: "QnA", value: "3" },
];

export const GUIDE_COLS = {
  tab: [
    "filename",
    "guide_ul_t",
    "checker",
    "set_name",
    "post_t",
    "status",
    "answer_t",
    "worker",
    "checker_qna",
  ],
  tab1: ["filename", "guide_ul_t", "checker"], // data set guide columns
  tab2: ["filename", "guide_ul_t", "checker", "set_name"], // work set guide columns
  tab3: ["filename", "post_t", "status", "answer_t", "worker", "checker_qna"], // QnA columns
};

export const REF_TAB_LINK = {
  ref: {
    name: "[작업 가이드 및 QnA]",
    search: "?tab=1&page=1&sort=guideUlT&sortType=desc",
  },
  data: {
    name: "[데이터 리스트]",
    search: "?tab=1&view=table&page=1&sort=filename&sortType=asc",
  },
};

export const COLUMN_LIST = [
  // 1. 패이지 이동
  {
    type: "link",
    name: "data",
    sort: false,
    ko: "데이터",
  },
  {
    type: "left",
    name: "dataset_name",
    col: "datasetName",
    ko: "데이터 코드",
  },
  // 2. collection 데이터 수집
  {
    name: "path",
    col: "path",
    ko: "수집경로",
  },
  {
    name: "port",
    col: "port",
    ko: "항만",
  },
  {
    name: "berth",
    col: "berth",
    ko: "선석",
  },
  {
    name: "vessel",
    col: "vessel",
    ko: "선박 또는 지역",
  },
  {
    name: "date",
    col: "date",
    icon: "IC_date_s",
    ko: "수집 날짜",
  },
  {
    name: "transferred",
    col: "transferred",
    ko: "데이터 이관",
  },
  {
    type: "step",
    name: "rework_cnt",
    col: "reworkCnt",
    ko: "재작업",
  },
  {
    name: "sensor_name",
    col: "sensorName",
    ko: "센서번호",
  },
  {
    name: "clct_t",
    col: "clctT",
    icon: "IC_time_s",
    ko: "수집시간",
  },
  {
    type: "filename",
    name: "filename",
    col: "filename",
    filter: false,
    ko: "파일명",
  },
  {
    type: "filesize",
    name: "filesize",
    col: "filesize",
    filter: false,
    ko: "용량",
  },
  // 원본 데이터, 상태별 데이터 수
  {
    type: "group",
    name: "sensor_cnts",
    ko: "센서별 데이터 수",
  },
  {
    type: "group",
    name: "img_cnts",
    ko: "원본 데이터 수",
  },
  {
    type: "cnts",
    name: "tab_cnts",
    ko: "상태별 데이터 수",
  },
  {
    type: "group",
    name: "cond_cnts",
    ko: "수집 환경",
  },
  // 2. collection 데이터 추출, 등록
  {
    type: "member",
    name: "ul_rep",
    col: "ulRep",
    ko: "log 데이터 업로드 담당자",
  },
  {
    name: "ul_t",
    col: "ulT",
    icon: "IC_datetime_s",
    ko: "log 데이터 업로드 시간",
  },
  {
    name: "guide_ul_t",
    col: "guideUlT",
    icon: "IC_datetime_s",
    ko: "업로드 시간",
  },
  {
    type: "member",
    name: "extr_rep",
    col: "extrRep",
    ko: "추출 담당자",
  },
  {
    name: "extr_t",
    col: "extrT",
    icon: "IC_datetime_s",
    ko: "추출 시간",
  },
  {
    type: "member",
    name: "rgs_rep",
    col: "rgsRep",
    ko: "데이터 등록 담당자",
  },
  {
    name: "rgs_t",
    col: "rgsT",
    icon: "IC_datetime_s",
    ko: "데이터 등록 시간",
  },

  {
    type: "member",
    name: "del_rep",
    col: "delRep",
    ko: "삭제 담당자",
  },
  {
    name: "del_date",
    col: "delDate",
    icon: "IC_date_s",
    ko: "삭제 날짜",
  },
  {
    name: "del_cuz",
    col: "delCuz",
    ko: "삭제 사유",
  },
  // 3. camera 수집환경 정보
  {
    type: "member",
    name: "cond_rep",
    col: "condRep",
    ko: "수집환경 입력 담당자",
  },
  {
    name: "cond_t",
    col: "condT",
    icon: "IC_datetime_s",
    ko: "수집환경 입력 시간",
  },
  {
    name: "snow",
    col: "snow",
    ko: "눈",
  },
  {
    name: "rain",
    col: "rain",
    ko: "비",
  },
  {
    name: "fog",
    col: "fog",
    ko: "안개",
  },
  {
    name: "light",
    col: "light",
    ko: "조명",
  },
  {
    name: "backlight",
    col: "backlight",
    ko: "역광",
  },
  {
    name: "night",
    col: "night",
    ko: "밤",
  },
  {
    name: "smoke",
    col: "smoke",
    ko: "연기",
  },
  // 4. 선별 정보
  {
    name: "orig_ul_t",
    col: "origUlT",
    icon: "IC_datetime_s",
    ko: "원본 데이터 업로드 시간",
  },
  {
    name: "tail_ul_t",
    col: "tailUlT",
    icon: "IC_datetime_s",
    ko: "Tailed 데이터 업로드 시간",
  },
  {
    name: "inrange_ul_t",
    col: "inrangeUlT",
    icon: "IC_datetime_s",
    ko: "Inrange 데이터 업로드 시간",
  },
  {
    name: "map_ul_t",
    col: "mapUlT",
    icon: "IC_datetime_s",
    ko: "참고 데이터 업로드 시간",
  },
  {
    type: "member",
    name: "vld_rep",
    col: "vldRep",
    ko: "선별 담당자",
  },
  {
    name: "vld_t",
    col: "vldT",
    icon: "IC_datetime_s",
    ko: "선별 시간",
  },
  {
    name: "inf_mo",
    col: "infMo",
    ko: "추론 모델",
  },
  {
    type: "code",
    name: "vld_type",
    col: "vldType",
    ko: "선별 유형",
  },
  {
    type: "vldAuto",
    name: "seg_auto_status",
    col: "segAutoStatus",
    ko: "자동 선별 현황",
  },
  {
    type: "vldAuto",
    name: "det_auto_status",
    col: "detAutoStatus",
    ko: "자동 추론 현황",
  },
  {
    name: "seg_auto_vld_options",
    col: "segAutoVldOptions",
    ko: "Conf step blur",
  },
  {
    name: "cam_seg_pri",
    col: "camSegPri",
    ko: "우선순위",
  },
  {
    name: "rad_seg_pri",
    col: "radSegPri",
    ko: "우선순위",
  },
  {
    name: "det_pri",
    col: "detPri",
    ko: "우선순위",
  },
  {
    type: "num",
    name: "cam_seg_eval_cnt",
    col: "camSegEvalCnt",
    ko: "평가용 데이터 수",
  },
  {
    type: "num",
    name: "rad_seg_eval_cnt",
    col: "radSegEvalCnt",
    ko: "평가용 데이터 수",
  },
  {
    type: "num",
    name: "det_eval_cnt",
    col: "detEvalCnt",
    ko: "평가용 데이터 수",
  },
  {
    type: "num",
    name: "cam_seg_val_cnt",
    col: "camSegValCnt",
    ko: "학습용 데이터 수",
  },
  {
    type: "num",
    name: "rad_seg_val_cnt",
    col: "radSegValCnt",
    ko: "학습용 데이터 수",
  },
  {
    type: "num",
    name: "det_val_cnt",
    col: "detValCnt",
    ko: "학습용 데이터 수",
  },
  // 5. 배분 정보
  {
    name: "difficulty",
    col: "difficulty",
    ko: "난이도",
  },
  {
    type: "num",
    name: "unit_price",
    col: "unitPrice",
    ko: "단가(장)",
  },
  {
    type: "num",
    name: "person_price",
    col: "personPrice",
    ko: "단가 (사람)",
  },
  {
    type: "num",
    name: "vessel_price",
    col: "vesselPrice",
    ko: "단가 (선박)",
  },
  {
    type: "num",
    name: "buoy_price",
    col: "buoyPrice",
    ko: "단가 (부표)",
  },
  {
    type: "num",
    name: "smoke_price",
    col: "smokePrice",
    ko: "단가 (매연)",
  },
  {
    type: "num",
    name: "fire_price",
    col: "firePrice",
    ko: "단가 (화재)",
  },
  {
    type: "member",
    name: "checker",
    col: "checker",
    ko: "검수자",
  },
  {
    type: "member",
    name: "checker_qna",
    ko: "검수자",
  },
  {
    type: "member",
    name: "change_req_checker",
    col: "changeReqChecker",
    ko: "변경 예정 검수자",
  },
  {
    type: "member",
    name: "worker",
    col: "worker",
    ko: "작업자",
  },
  {
    name: "label_v",
    col: "labelV",
    ko: "라벨버전",
  },
  {
    name: "tracked",
    col: "tracked",
    ko: "트래킹 여부",
  },
  {
    type: "member",
    name: "dist_rep",
    col: "distRep",
    ko: "배분 담당자",
  },
  {
    name: "dist_rep_name",
    col: "distRepName",
    ko: "배분 담당자",
  },
  {
    name: "dist_t",
    col: "distT",
    icon: "IC_datetime_s",
    ko: "배분시간",
  },
  {
    name: "dist_date",
    col: "distDate",
    icon: "IC_date_s",
    ko: "배분날짜",
  },
  {
    name: "set_name",
    col: "setName",
    ko: "작업 셋 이름",
  },
  {
    type: "num",
    name: "ids_num",
    col: "idsNum",
    sort: true,
    ko: "track id 수",
  },
  {
    type: "num",
    name: "person_obj_num",
    col: "personObjNum",
    sort: false,
    ko: "총 사람 객체 수",
  },
  {
    type: "num",
    name: "vessel_obj_num",
    col: "vesselObjNum",
    sort: false,
    ko: "총 선박 객체 수",
  },
  {
    type: "num",
    name: "buoy_obj_num",
    col: "buoyObjNum",
    sort: false,
    ko: "총 부표 객체 수",
  },
  {
    type: "num",
    name: "smoke_obj_num",
    col: "smokeObjNum",
    sort: false,
    ko: "총 연기 객체 수",
  },
  {
    type: "num",
    name: "fire_obj_num",
    col: "fireObjNum",
    sort: false,
    ko: "총 화재 객체 수",
  },
  {
    type: "num",
    name: "work_ids_num",
    sort: false,
    ko: "track id 수",
  },
  {
    type: "num",
    name: "work_person_obj_num",
    sort: false,
    ko: "총 사람 객체 수",
  },
  {
    type: "num",
    name: "work_vessel_obj_num",
    sort: false,
    ko: "총 선박 객체 수",
  },
  {
    type: "num",
    name: "work_buoy_obj_num",
    sort: false,
    ko: "총 부표 객체 수",
  },
  {
    type: "num",
    name: "work_smoke_obj_num",
    sort: false,
    ko: "총 연기 객체 수",
  },
  {
    type: "num",
    name: "work_fire_obj_num",
    sort: false,
    ko: "총 화재 객체 수",
  },
  {
    type: "num",
    name: "lrn_person_obj_num",
    col: "lrnPersonObjNum",
    sort: false,
    ko: "총 사람 객체 수",
  },
  {
    type: "num",
    name: "lrn_vessel_obj_num",
    col: "lrnVesselObjNum",
    sort: false,
    ko: "총 선박 객체 수",
  },
  {
    type: "num",
    name: "lrn_buoy_obj_num",
    col: "lrnBuoyObjNum",
    sort: false,
    ko: "총 부표 객체 수",
  },
  {
    type: "num",
    name: "lrn_smoke_obj_num",
    col: "lrnSmokeObjNum",
    sort: false,
    ko: "총 연기 객체 수",
  },
  {
    type: "num",
    name: "lrn_fire_obj_num",
    col: "lrnFireObjNum",
    sort: false,
    ko: "총 화재 객체 수",
  },
  {
    type: "code",
    name: "status",
    col: "status",
    ko: "상태",
  },
  // 6. 작업, 검수 정보
  {
    name: "orig_dwl_t",
    col: "origDwlT",
    icon: "IC_datetime_s",
    ko: "원본 데이터 다운로드 시간",
  },
  {
    name: "inrange_dwl_t",
    col: "inrangeDwlT",
    icon: "IC_datetime_s",
    ko: "Inrange 데이터 다운로드 시간",
  },
  {
    name: "map_dwl_t",
    col: "mapDwlT",
    icon: "IC_datetime_s",
    ko: "참고 데이터 다운로드 시간",
  },
  {
    name: "gt_ul_t",
    col: "gtUlT",
    icon: "IC_datetime_s",
    ko: "라벨 데이터 업로드 시간",
  },
  {
    name: "gen_seg_gt_ul_t",
    col: "genSegGTUlT",
    icon: "IC_datetime_s",
    ko: "라벨 png 데이터 업로드 시간",
  },
  {
    name: "gen_det_gt_ul_t",
    col: "genDetGTUlT",
    icon: "IC_datetime_s",
    ko: "라벨 json 데이터 업로드 시간",
  },
  {
    type: "member",
    name: "ul_checker",
    col: "ulChecker",
    ko: "라벨 데이터 업로드 담당자",
  },
  {
    name: "insp_t",
    col: "inspT",
    icon: "IC_datetime_s",
    ko: "에러검사 시간",
  },
  {
    name: "check_insp_t",
    col: "checkInspT",
    icon: "IC_datetime_s",
    ko: "검수자 에러검사 시간",
  },
  {
    name: "check_req_t",
    col: "checkReqT",
    icon: "IC_datetime_s",
    ko: "검수요청 시간",
  },
  {
    name: "check_t",
    col: "checkT",
    icon: "IC_datetime_s",
    ko: "검수 시간",
  },
  {
    name: "payment_date",
    col: "paymentDate",
    icon: "IC_date_s",
    ko: "대금지급 날짜",
  },
  {
    type: "arr",
    name: "label_vs",
    sort: false,
    ko: "라벨버전 정보",
  },
  {
    type: "num",
    name: "lrn_cam_seg_cnt",
    col: "lrnCamSegCnt",
    ko: "학습 데이터 수",
  },
  {
    type: "num",
    name: "lrn_det_cnt",
    col: "lrnDetCnt",
    ko: "학습 데이터 수",
  },
  {
    type: "num",
    name: "lrn_rad_seg_cnt",
    col: "lrnRadSegCnt",
    ko: "학습 데이터 수",
  },
  {
    name: "gt_tab_cnts",
    sort: false,
    ko: "학습반영 비율",
  },
  {
    name: "check_date",
    col: "checkDate",
    icon: "IC_date_s",
    ko: "학습반영 날짜",
  },
  {
    name: "latest_check_date",
    col: "latestCheckDate",
    icon: "IC_date_s",
    ko: "최초 학습반영 날짜",
  },
  {
    name: "check_ul_t",
    col: "checkUlT",
    icon: "IC_datetime_s",
    ko: "학습 데이터 갱신 시간",
  },
  {
    name: "latest_check_ul_t",
    col: "latestCheckUlT",
    icon: "IC_datetime_s",
    ko: "최근 학습 데이터 갱신 시간",
  },
  {
    name: "cmp_date",
    col: "cmpDate",
    icon: "IC_date_s",
    ko: "작업 셋 검수완료 날짜",
  },
  {
    name: "fail_type",
    col: "failType",
    ko: "에러 타입",
  },
  // 에러검사
  {
    type: "insp",
    name: "errs_exist",
    col: "errsExist",
    ko: "에러 유무",
  },
  {
    type: "insp_result",
    name: "empty_pixels",
    sort: false,
    ko: "비어있는 픽셀",
  },
  {
    name: "name",
    sort: false,
    ko: "레이어명",
  },
  {
    type: "insp_result",
    name: "obj_numbering_err",
    sort: false,
    ko: "객체 넘버 오류",
  },
  {
    type: "insp_result",
    name: "layer_naming_err",
    sort: false,
    ko: "레이어명 오류",
  },
  {
    type: "insp_result",
    name: "empty_layer",
    sort: false,
    ko: "빈 레이어",
  },
  {
    type: "insp_result",
    name: "labeling_err",
    sort: false,
    ko: "채색 오류",
  },
  {
    type: "insp_result",
    name: "opacity_err",
    sort: false,
    ko: "불투명도 오류",
  },
  {
    type: "insp_result",
    name: "invisible_layer",
    sort: false,
    ko: "레이어 꺼짐",
  },
  {
    type: "insp_result",
    name: "group_err",
    sort: false,
    ko: "그룹 에러",
  },
  {
    type: "insp_result",
    name: "duplicate_warn",
    sort: false,
    ko: "중복 경고",
  },
  {
    type: "insp_result",
    name: "hidden_err",
    sort: false,
    ko: "숨겨진 객체",
  },
  {
    type: "insp_result",
    name: "bg_err",
    sort: false,
    ko: "백그라운드 에러",
  },
  {
    name: "post_t",
    col: "postT",
    ko: "질문 등록 시간",
  },
  {
    name: "answer_t",
    col: "answerT",
    ko: "답변 등록 시간",
  },
  {
    name: "cncl_req_t",
    col: "cnclReqT",
    icon: "IC_datetime_s",
    ko: "작업회수요청 시간",
  },
  {
    name: "cncl_cuz",
    col: "cnclCuz",
    ko: "작업회수 사유",
  },
  {
    name: "cncl_t",
    col: "cnclT",
    icon: "IC_datetime_s",
    ko: "작업회수 시간",
  },
  // 데이터 count

  {
    type: "num",
    name: "gt_cnt",
    sort: false,
    ko: "데이터 수",
  },
  {
    type: "num",
    name: "cnt",
    sort: false,
    ko: "데이터 수",
  },
  // 5. 데이터 셋 완료 정보
  {
    name: "cond_completed",
    col: "condCompleted",
    ko: "수집환경 입력 완료 여부",
  },
  {
    name: "cam_seg_vld_cmp_t",
    col: "camSegVldCmpT",
    icon: "IC_datetime_s",
    ko: "선별 완료 시간",
  },
  {
    name: "det_vld_cmp_t",
    col: "detVldCmpT",
    icon: "IC_datetime_s",
    ko: "선별 완료 시간",
  },
  {
    name: "rad_seg_vld_cmp_t",
    col: "radSegVldCmpT",
    icon: "IC_datetime_s",
    ko: "선별 완료 시간",
  },
  {
    name: "cam_seg_dist_completed",
    col: "camSegDistCompleted",
    ko: "배분 완료 여부",
  },
  {
    name: "rad_seg_dist_completed",
    col: "radSegDistCompleted",
    ko: "배분 완료 여부",
  },
  {
    name: "det_dist_completed",
    col: "detDistCompleted",
    ko: "배분 완료 여부",
  },
  {
    name: "distributed",
    col: "distributed",
    ko: "배분 여부",
  },
  {
    name: "cam_seg_revalidating",
    col: "camSegRevalidating",
    ko: "재선별 중",
  },
  {
    name: "cam_det_revalidating",
    col: "camDetRevalidating",
    ko: "재선별 중",
  },
  {
    name: "rad_seg_revalidating",
    col: "radSegRevalidating",
    ko: "재선별 중",
  },
  {
    name: "cam_seg_check_date",
    col: "camSegCheckDate",
    icon: "IC_date_s",
    ko: "최근 학습반영 날짜",
  },
  {
    name: "det_check_date",
    col: "detCheckDate",
    icon: "IC_date_s",
    ko: "최근 학습반영 날짜",
  },
  {
    name: "rad_seg_check_date",
    col: "radSegCheckDate",
    icon: "IC_date_s",
    ko: "최근 학습반영 날짜",
  },
  // lrn 정보
  {
    name: "mask_created",
    col: "maskCreated",
    ko: "mask 데이터 생성 여부",
  },
  {
    name: "mask_t",
    col: "maskT",
    icon: "IC_datetime_s",
    ko: "mask 데이터 생성 시간",
  },
  {
    name: "ul_cnt",
    col: "ulCnt",
    ko: "라벨 데이터 버전",
  },
  // 데이터 셋 등록 시간
  {
    name: "crt_t",
    col: "crtT",
    icon: "IC_datetime_s",
    ko: "등록 시간",
  },
  {
    name: "gt_crt_t",
    col: "gtCrtT",
    icon: "IC_datetime_s",
    ko: "작업 셋 등록 시간",
  },
  {
    name: "clct_cmp_t",
    col: "clctCmpT",
    icon: "IC_datetime_s",
    ko: "데이터 셋 등록완료 시간",
  },
  // 6. 데이터 셋 정보 (우선 아님)
  {
    name: "module",
    col: "module",
    ko: "모듈명",
  },
];

export const IMG_TYPE_LIST = [
  { thumb: "auto_thumb", pre: "auto", ko: "Auto" },
  { thumb: "orig_thumb", pre: "orig", ko: "원본" },
  { thumb: "tail_thumb", pre: "tail", ko: "Tailed" },
  { thumb: "inrange_thumb", pre: "inrange", ko: "Inrange" },
  { thumb: "gt_thumb", pre: "gt", ko: "작업" },
  { thumb: "gt_seg_thumb", pre: "gt_seg", ko: "seg 작업" },
  { thumb: "gt_det_thumb", pre: "gt_det", ko: "det 작업" },
  { thumb: "inf_thumb", pre: "inf", ko: "추론" },
  { thumb: "map_thumb", pre: "map", ko: "참고" },
  { thumb: "blend_thumb", pre: "blend", ko: "혼합" },
  { thumb: "bbox_thumb", pre: "bbox", ko: "혼합(bbox)" },
  { thumb: "inrange_blend_thumb", pre: "blend", ko: "혼합(Inrange)" },
  { thumb: "gt_blend_thumb", pre: "gt_blend", ko: "혼합(참고)" },
];

// 선별 유형 선택
export const LRN_TAG_VALUE = {
  WAIT: 1, // 대기
  ADD: 2, // 학습용
  EXCEPT: 3, // 제외
};

// 자동 선별 현황
export const VLD_AUTO_VALUE = {
  NONE: 1, // X
  BOOK: 2, // 예약
  AUTO: 3, // 진행중
  DONE: 4, // 완료
};
