import clsx from "clsx";
import { useRecoilValue } from "recoil";
import { currentFilterPosState } from "../../lib/stores";
import { MiddlePoint, ImageSprite } from "./CommonAtoms";
import { CommonButton } from "../molecules/Buttons";

export const SelectWrap = ({ single, required, children, styleType }) => {
  // 선택 박스 molecule
  return single ? (
    <div className="relative">{children}</div>
  ) : (
    <li
      className={clsx(
        "relative",
        required && "text-error",
        styleType !== "stats" &&
          styleType !== "pagination" &&
          styleType !== "sort" &&
          "flex h-[44px] items-center gap-[8px]"
      )}
    >
      {children}
    </li>
  );
};

export const SelectName = ({ name }) => {
  // 선택 옵션 이름
  return (
    <div className="flex h-[40px] w-[90px] items-center gap-[4px]">
      <MiddlePoint />
      <span>{name} :</span>
    </div>
  );
};

export const SelectTitle = ({ onClick, name, styleType }) => {
  // 선택 박스 닫힘
  return (
    <div
      id="select_title"
      className={clsx(
        "flex h-[30px] w-[140px] cursor-pointer items-center justify-between rounded-[2px] border border-gray400 px-[12px]",
        styleType === "pagination"
          ? "bottom-[29px] top-[inherit] w-[80px] min-w-[80px]"
          : styleType === "modal"
            ? "w-[234px]"
            : ""
      )}
      onClick={onClick}
    >
      <span className="text-gray800">{name}</span>
      <ImageSprite iconType="IC_down_k" />
    </div>
  );
};

export const SelectBox = (props) => {
  const { thumbHead, open, children, styleType, cn } = props;

  const currentFilterPos = useRecoilValue(currentFilterPosState);

  // 선택 옵션 리스트 박스 열림
  return (
    open && (
      <div
        className={clsx(
          "absolute inset-x-0 z-30 max-h-[354px] rounded-[2px] border border-gray400 bg-white font-normal",
          styleType === "pagination"
            ? "bottom-[29px] top-[inherit] w-[80px] min-w-[80px]"
            : styleType === "modal"
              ? "left-[98px] top-[36px] w-[234px]"
              : styleType === "filter"
                ? "left-[-16px] top-[7px] min-w-[140px]"
                : styleType === "stats_option"
                  ? "top-[29px] min-w-[140px]"
                  : styleType === "sort"
                    ? "top-[29px] min-w-[140px]"
                    : "top-[29px] min-w-[140px]",
          cn
        )}
        // 기존 -16 + -{scrollLeft}
        style={thumbHead ? { left: -16 + -currentFilterPos } : {}}
      >
        {styleType === "sort" || styleType === "filter" ? (
          children
        ) : (
          <SelectUl>{children}</SelectUl>
        )}
      </div>
    )
  );
};

export const SelectUl = ({ children, cn }) => {
  // 선택 옵션 리스트
  return <ul className={clsx("max-h-[272px] overflow-y-auto py-[4px]", cn)}>{children}</ul>;
};

export const SelectItem = ({
  item,
  children,
  pagination,
  checked,
  styleType,
  cn,
  bool,
  alignRight,
}) => {
  // 선택 옵션 리스트 아이템
  return (
    <label
      className={clsx(
        "flex cursor-pointer items-center text-gray800",
        styleType === "list" && "h-[30px] px-[12px]",
        checked && "bg-gray200",
        alignRight && "justify-between"
      )}
    >
      {children}
      {(item === 0 || item) && (
        <span className={clsx("ml-[12px]", cn, bool === false && "!text-gray400")}>
          {pagination ? item : item.ko}
        </span>
      )}
    </label>
  );
};

export const SelectCheckbox = ({ onChange, checked, value, tr, bool }) => {
  // 체크박스
  return (
    <>
      {!tr && (
        <input
          onChange={onChange}
          type="checkbox"
          checked={checked}
          value={value}
          className="hidden"
        />
      )}
      <div
        className={clsx(
          "relative h-[20px] w-[20px] flex-none border bg-white",
          bool === false ? "border-gray300" : "border-gray500",
          checked &&
            `border-none after:absolute after:left-[7px] after:top-[3px] after:h-[9px] after:w-[4px] after:rotate-45 after:border-2 after:border-l-0 after:border-t-0 after:border-white`,
          checked && (bool === false ? `bg-gray600` : `bg-primary`)
        )}
      ></div>
    </>
  );
};

export const SelectRadio = ({
  onChange,
  checked,
  value,
  cn,
  styleType,
  bool,
  disabled = false,
}) => {
  // 라디오
  return (
    <>
      <input
        onChange={onChange}
        type="radio"
        checked={checked}
        value={value}
        className="hidden"
        disabled={disabled}
      />
      <div
        className={clsx(
          cn,
          "relative h-[20px] w-[20px] flex-none rounded-[50%] border border-gray500",
          styleType !== "white" && "bg-white",
          checked &&
            `after:absolute after:left-[50%] after:top-[50%] after:h-[10px] after:w-[10px] after:translate-x-[-50%] after:translate-y-[-50%] after:rounded-[5px]`,
          checked &&
            (styleType === "white"
              ? "border-white after:bg-white"
              : bool === false
                ? "border-gray600 after:bg-gray600"
                : "border-primary after:bg-primary")
        )}
      ></div>
    </>
  );
};

export const SelectButton = ({ onClick }) => {
  // 선택 박스 하단 적용 버튼
  return (
    <div className="flex h-[48px] items-center pl-[12px]">
      <CommonButton onClick={onClick} name="적용" />
    </div>
  );
};
