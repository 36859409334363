import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import {
  GT_TAB_CAMDET_WORK,
  GT_TAB_CAMSEG_WORK,
  GT_TAB_RADSEG_WORK,
  REF_TAB_LINK,
  DATA_STATUS_CODE,
} from "../../lib/constants/constant";
import { makeImgTypeList, WORK_DATA_COLS } from "./Work.constants";
import { workFilter } from "./Work.apis";
import { workCntQuery, workIdsQuery, workListQuery, workPreviewQuery } from "./Work.stores";
import {
  DownloadErr,
  DownloadGt,
  DownloadMap,
  DownloadOrigAll,
  DownloadOrigin,
  Inspect,
  SetCheckReq,
  SetReturnReq,
  SetWorkStart,
  GtUpload,
  DownloadInrange,
  DownloadInrangeAll,
} from "./WorkActions";

const WorkDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    workCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    workIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  return (
    <CommonTemplate
      listQuery={workListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={workFilter}
      previewQuery={workPreviewQuery}
      fullColumnData={WORK_DATA_COLS[`${sensor_code}_${gt_code}`].tab1}
      columnData={WORK_DATA_COLS[`${sensor_code}_${gt_code}`][`tab${tab}`]}
      hideColumn={WORK_DATA_COLS[`${sensor_code}_${gt_code}`].hide.table[`tab${tab}`]}
      hideThumbColumn={WORK_DATA_COLS[`${sensor_code}_${gt_code}`].hide.thumb[`tab${tab}`]}
      tabData={
        sensor_code === "rad"
          ? GT_TAB_RADSEG_WORK
          : gt_code === "det"
            ? GT_TAB_CAMDET_WORK
            : GT_TAB_CAMSEG_WORK
      }
      refTabData={REF_TAB_LINK.ref}
      viewData
      imgTypeData={makeImgTypeList(sensor_code, gt_code)}
      statusCodeData={DATA_STATUS_CODE}
      tagType="work"
      actions={renderUtilButtons(sensor_code, gt_code, tab)}
    />
  );
};

const renderUtilButtons = (() => {
  const TABS = {
    전체: 1,
    작업대기: 4,
    작업중: 5,
    검수대기: 6,
    검수반려: 7,
    검수통과: 8,
    작업회수: 10,
  };

  return (sensor_code, gt_code, tabNumber) =>
    ({ selectedItems, contents }) => {
      const buttonsForAllContents = {
        cam: {
          seg: {
            [TABS.작업대기]: (
              <>
                <DownloadOrigAll />
                {/* <DownloadGtPsdAll /> */}
              </>
            ),
            [TABS.작업중]: <GtUpload />,
            [TABS.검수반려]: <GtUpload />,
          },
          det: {
            [TABS.작업대기]: (
              <>
                <DownloadOrigAll />
                {/* <DownloadGtPsdAll /> */}
              </>
            ),
            [TABS.작업중]: <GtUpload />,
            [TABS.검수반려]: <GtUpload />,
          },
        },
        rad: {
          seg: {
            [TABS.작업대기]: (
              <>
                <DownloadInrangeAll selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: <GtUpload />,
            [TABS.검수반려]: <GtUpload />,
          },
        },
      };

      const buttonsForSelectedContents = {
        cam: {
          seg: {
            [TABS.전체]: <SetReturnReq selectedItems={selectedItems} />,
            [TABS.작업대기]: (
              <>
                <DownloadOrigin selectedItems={selectedItems} />
                {/* <DownloadGtPsd selectedItems={selectedItems} /> */}
                <SetWorkStart selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: (
              <>
                <Inspect selectedItems={selectedItems} contents={contents?.results} />
                <DownloadErr selectedItems={selectedItems} />
                <DownloadGt selectedItems={selectedItems} />
                <SetCheckReq selectedItems={selectedItems} />
              </>
            ),
          },
          det: {
            [TABS.전체]: <SetReturnReq selectedItems={selectedItems} />,
            [TABS.작업대기]: (
              <>
                <DownloadOrigin selectedItems={selectedItems} />
                {/* <DownloadGtPsd selectedItems={selectedItems} /> */}
                <SetWorkStart selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: (
              <>
                <Inspect selectedItems={selectedItems} contents={contents?.results} />
                <DownloadGt selectedItems={selectedItems} />
                <SetCheckReq selectedItems={selectedItems} />
              </>
            ),
          },
        },
        rad: {
          seg: {
            [TABS.전체]: <SetReturnReq selectedItems={selectedItems} />,
            [TABS.작업대기]: (
              <>
                <DownloadInrange selectedItems={selectedItems} />
                <DownloadMap selectedItems={selectedItems} />
                <SetWorkStart selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: (
              <>
                <Inspect selectedItems={selectedItems} contents={contents?.results} />
                <DownloadGt selectedItems={selectedItems} />
                <SetCheckReq selectedItems={selectedItems} />
              </>
            ),
          },
        },
      };

      return (
        <>
          {buttonsForAllContents[sensor_code]?.[gt_code]?.[tabNumber]}
          <SelectCnt count={selectedItems.length} />
          {buttonsForSelectedContents[sensor_code]?.[gt_code]?.[tabNumber]}
        </>
      );
    };
})();

export default WorkDataPage;
