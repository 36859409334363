import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const WORK_CLCT_COLS = {
  seg: {
    tab: ["data", "path", "port", "berth", "vessel", "date", "tab_cnts"],
  },
  det: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "tab_cnts",
      "tracked",
      "work_ids_num",
      "work_person_obj_num",
      "work_vessel_obj_num",
      "work_buoy_obj_num",
      "work_smoke_obj_num",
      "work_fire_obj_num",
    ],
    hide: [
      "tracked",
      "work_vessel_obj_num",
      "work_buoy_obj_num",
      "work_fire_obj_num",
      "work_smoke_obj_num",
    ],
  },
};

export const WORK_DATA_COLS = {
  cam_seg: {
    // 전체
    tab1: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
      "errs_exist",
      "empty_pixels",
      "obj_numbering_err",
      "layer_naming_err",
      "empty_layer",
      "labeling_err",
      "opacity_err",
      "invisible_layer",
      "group_err",
      "duplicate_warn",
      "hidden_err",
      "bg_err",
      "check_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    // 작업대기
    tab4: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
    ],
    // 작업중
    tab5: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "insp_t",
      "errs_exist",
      "empty_pixels",
      "obj_numbering_err",
      "layer_naming_err",
      "empty_layer",
      "labeling_err",
      "opacity_err",
      "invisible_layer",
      "group_err",
      "duplicate_warn",
      "hidden_err",
      "bg_err",
    ],
    // 검수대기
    tab6: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
    ],
    // 검수반려
    tab7: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
      "check_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
    ],
    // 검수통과
    tab8: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
      "check_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
    ],
    // 작업회수
    tab10: [
      "sensor_name",
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    hide: {
      table: {
        tab1: [
          "label_v",
          "errs_exist",
          "empty_pixels",
          "obj_numbering_err",
          "layer_naming_err",
          "empty_layer",
          "labeling_err",
          "opacity_err",
          "invisible_layer",
          "group_err",
          "duplicate_warn",
          "hidden_err",
          "bg_err",
        ],
        tab4: ["label_v", "status"],
        tab5: ["label_v", "orig_dwl_t"],
        tab6: ["label_v", "status", "orig_dwl_t", "insp_t"],
        tab7: ["label_v", "status", "orig_dwl_t", "insp_t"],
        tab8: ["label_v", "status", "orig_dwl_t", "gt_ul_t", "insp_t"],
        tab10: ["label_v", "status", "orig_dwl_t", "gt_ul_t"],
      },
      thumb: {
        tab1: [
          "filename",
          "label_v",
          "errs_exist",
          "empty_pixels",
          "obj_numbering_err",
          "layer_naming_err",
          "empty_layer",
          "labeling_err",
          "opacity_err",
          "invisible_layer",
          "group_err",
          "duplicate_warn",
          "hidden_err",
          "bg_err",
        ],
        tab4: ["filename", "label_v", "status"],
        tab5: ["filename", "label_v", "orig_dwl_t"],
        tab6: ["filename", "label_v", "status", "orig_dwl_t", "insp_t"],
        tab7: ["filename", "label_v", "status", "orig_dwl_t", "insp_t"],
        tab8: ["filename", "label_v", "status", "orig_dwl_t", "gt_ul_t", "insp_t"],
        tab10: ["filename", "label_v", "status", "orig_dwl_t", "gt_ul_t"],
      },
    },
  },
  cam_det: {
    // 전체 1
    tab1: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
      "check_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
      "fail_type",
      "cncl_req_t",
      "cncl_cuz",
    ],
    // 작업대기 4
    tab4: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
    ],
    // 작업중 5
    tab5: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "fail_type",
    ],
    // 검수대기 6
    tab6: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
    ],
    // 검수반려 7
    tab7: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
      "check_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
    ],
    // 검수통과 8
    tab8: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
      "check_t",
      "gt_ul_t",
      "insp_t",
      "check_req_t",
    ],
    // 작업회수 10
    tab10: [
      "sensor_name",
      "filename",
      "difficulty",
      "smoke",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "tracked",
      "status",
      "orig_dwl_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    hide: {
      table: {
        tab1: ["smoke", "label_v", "tracked", "fail_type"],
        tab4: ["smoke", "label_v", "tracked", "status"],
        tab5: ["smoke", "label_v", "tracked", "orig_dwl_t"],
        tab6: ["smoke", "label_v", "tracked", "status", "orig_dwl_t", "insp_t"],
        tab7: ["smoke", "label_v", "tracked", "status", "orig_dwl_t", "insp_t"],
        tab8: ["smoke", "label_v", "tracked", "status", "orig_dwl_t", "gt_ul_t", "insp_t"],
        tab10: ["smoke", "label_v", "tracked", "status", "orig_dwl_t", "gt_ul_t"],
      },
      thumb: {
        tab1: ["filename", "label_v", "tracked", "fail_type"],
        tab4: ["filename", "label_v", "tracked", "status"],
        tab5: ["filename", "label_v", "tracked", "orig_dwl_t"],
        tab6: ["filename", "label_v", "tracked", "status", "orig_dwl_t", "insp_t"],
        tab7: ["filename", "label_v", "tracked", "status", "orig_dwl_t", "insp_t"],
        tab8: ["filename", "label_v", "tracked", "status", "orig_dwl_t", "gt_ul_t", "insp_t"],
        tab10: ["filename", "label_v", "tracked", "status", "orig_dwl_t", "gt_ul_t"],
      },
    },
  },
  rad_seg: {
    tab1: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "fail_type",
      "cncl_req_t",
      "cncl_cuz",
    ],
    tab4: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    tab5: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "fail_type",
      "cncl_req_t",
      "cncl_cuz",
    ],
    tab6: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    tab7: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    tab8: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    tab10: [
      "filename",
      "difficulty",
      "checker",
      "set_name",
      "dist_date",
      "label_v",
      "status",
      "inrange_dwl_t",
      "map_dwl_t",
      "check_t",
      "check_req_t",
      "gt_ul_t",
      "cncl_req_t",
      "cncl_cuz",
    ],
    hide: {
      table: {
        tab1: [],
        tab4: [],
        tab5: [],
        tab6: [],
        tab7: [],
        tab8: [],
        tab10: [],
      },
      thumb: {
        tab1: ["filename"],
        tab4: ["filename"],
        tab5: ["filename"],
        tab6: ["filename"],
        tab7: ["filename"],
        tab8: ["filename"],
        tab10: ["filename"],
      },
    },
  },
};

export const makeImgTypeList = (sensor_code, gt_code) =>
  IMG_TYPE_LIST.filter((x) =>
    (sensor_code === "rad"
      ? ["auto_thumb", "inrange_thumb", "gt_thumb", "inrange_blend_thumb", "gt_blend_thumb"]
      : gt_code === "det"
        ? ["auto_thumb", "orig_thumb", "blend_thumb"]
        : ["auto_thumb", "orig_thumb", "blend_thumb", "bbox_thumb", "gt_thumb"]
    ).includes(x.thumb)
  );
