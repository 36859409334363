import { TextField } from "@mui/material";
import { useState } from "react";
import {
  SelectBox,
  SelectItem,
  SelectName,
  SelectRadio,
  SelectTitle,
  SelectWrap,
} from "../../components/atoms/SelectBox";
import { CommonButton } from "../../components/molecules/Buttons";
import { SaveModal } from "../../components/molecules/CommonModals";
import {
  ConfirmButton,
  DataDeleteConfirmationButton,
  DelCheckButton,
  FloatingButton,
  FloatingInsertButton,
} from "../../components/organisms/actions";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { useModals, useReload } from "../../lib/hooks";
import { checkInputText } from "../../lib/utils";
import {
  rgsClctCmp,
  rgsClctCreate,
  rgsClctDel,
  rgsClctGetInfo,
  rgsDataDel,
  rgsDelCheck,
  rgsDetGtUl,
  rgsInrangeUl,
  rgsMapUl,
  rgsOrigUl,
  rgsSegGtUl,
  rgsTailUl,
} from "./Rgs.apis";
import { RGS_SET_TYPE } from "./Rgs.constants";

export const RgsClctCreate = ({ sensorType }) => {
  const {
    queryParams: { tab },
  } = useDynamicRouting();
  const [infoList, setInfoList] = useState({});
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState({
    path: "",
    port: "",
    berth: "",
    vessel: "",
    date: "",
    module: "",
  });

  const { listReload } = useReload();
  const [open, setOpen] = useState(null);
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const resetOpen = () => {
    getInfoList();
    setValue({
      path: "",
      port: "",
      berth: "",
      vessel: "",
      date: "",
      module: "",
    });
    setModalStatus(null);
    setFailType(null);
    setRequired(false);
    openModal();
  };

  const getInfoList = async () => {
    setModalStatus("loading");

    try {
      const data = await rgsClctGetInfo();

      setInfoList(data.values);
      setModalStatus(null);
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";
      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  const checkInput = () => {
    if (
      !value.path ||
      (value.path === RGS_SET_TYPE.PORT && !value.port) ||
      (value.path === RGS_SET_TYPE.PORT && !value.berth) ||
      (value.path === RGS_SET_TYPE.SAIL && !value.vessel) ||
      !value.date ||
      !value.module
    ) {
      setModalStatus("hasError");
      setFailType("필수 정보를 입력해 주세요.");
      setRequired(true);
    } else if (checkInputText([value.vessel, value.module])) {
      setModalStatus("hasError");
      setFailType("대소문자 영문, 숫자, 특수문자(- _)만 사용가능합니다.");
      setRequired(true);
    } else {
      handleClick();
    }
  };

  const handleClick = async () => {
    setModalStatus("loading");
    const tab_id = tab;
    const combineValue = { ...value, tab_id };
    try {
      const data = await rgsClctCreate(combineValue);

      closeModal();
      listReload();
    } catch (err) {
      console.log(err?.response);

      let err_detail = err?.response?.data?.detail || "";

      setModalStatus("hasError");
      setFailType(err_detail);
    }
  };

  return (
    <>
      <CommonButton name="데이터 셋 등록" icon="IC_add_w" onClick={resetOpen} />

      {modal && (
        <SaveModal
          closeModal={closeModal}
          title="데이터 셋 등록 정보 입력"
          modalStatus={modalStatus}
          handleClick={checkInput}
          failType={failType}
        >
          <SelectWrap required={required && !value.path}>
            <SelectName name="수집경로" />
            {Object.values(RGS_SET_TYPE)
              .filter((x) => sensorType === "cam" || x !== "G")
              .map((x, i) => (
                <SelectItem key={i}>
                  <SelectRadio
                    onChange={(e) => {
                      setValue((value) => {
                        return {
                          path: x,
                          port: "",
                          berth: "",
                          vessel: "",
                          date: "",
                          module: "",
                        };
                      });
                    }}
                    checked={value.path === x}
                    value={x}
                  />
                  <div className="pl-[8px]">{x}</div>
                </SelectItem>
              ))}
          </SelectWrap>
          {value.path === RGS_SET_TYPE.PORT && (
            <>
              <SelectWrap required={required && !value.port}>
                <SelectName name="(P) 항만" />
                <SelectTitle
                  onClick={() => setOpen(open ? null : "port")}
                  name={value.port || "선택"}
                  styleType="modal"
                />
                <SelectBox open={open === "port"} styleType="modal">
                  {infoList.port.map((x, i) => (
                    <SelectItem key={i} item={x.port} pagination styleType="list">
                      <SelectRadio
                        onChange={(e) => {
                          setValue({ ...value, port: x.port });
                          setOpen(null);
                        }}
                        checked={value.port === x.port}
                        value={x.port}
                      />
                    </SelectItem>
                  ))}
                </SelectBox>
              </SelectWrap>
              <SelectWrap required={required && !value.berth}>
                <SelectName name="(P) 선석" />
                <SelectTitle
                  onClick={() => setOpen(open ? null : "berth")}
                  name={value.berth || "선택"}
                  styleType="modal"
                />
                <SelectBox open={open === "berth"} styleType="modal">
                  {infoList?.port
                    ?.find((item) => item.port === value.port)
                    ?.berths?.map((x, i) => (
                      <SelectItem key={i} item={x} pagination styleType="list">
                        <SelectRadio
                          onChange={(e) => {
                            setValue({ ...value, berth: x });
                            setOpen(null);
                          }}
                          checked={value.berth === x}
                          value={x}
                        />
                      </SelectItem>
                    ))}
                </SelectBox>
              </SelectWrap>
            </>
          )}
          {value.path === RGS_SET_TYPE.SAIL && (
            <SelectWrap required={required && !value.vessel}>
              <SelectName name="(S) 선박 또는 지역" />
              <TextField
                hiddenLabel
                size="small"
                onChange={(e) => {
                  setValue({ ...value, vessel: e.target.value });
                }}
                inputProps={{ maxLength: 40 }}
                value={value.vessel || ""}
              />
            </SelectWrap>
          )}
          {value.path === RGS_SET_TYPE.GENERATED && (
            <SelectWrap required={required && !value.vessel}>
              <SelectName name="(G) 선박 또는 지역" />
              <TextField
                hiddenLabel
                size="small"
                onChange={(e) => {
                  setValue({ ...value, vessel: e.target.value });
                }}
                inputProps={{ maxLength: 40 }}
                value={value.vessel || ""}
              />
            </SelectWrap>
          )}
          <SelectWrap required={required && !value.date}>
            <SelectName name="수집날짜" />
            <TextField
              type="date"
              size="small"
              sx={{ width: 160 }}
              onChange={(e) => {
                setValue({ ...value, date: e.target.value });
              }}
              inputProps={{
                min: "2000-01-01",
                max: new Date().toISOString().split("T")[0], // 추후에 date를 활용이 많아진다면 관련 라이브러리로 변경 추천
              }}
            />
          </SelectWrap>
          <SelectWrap required={required && !value.module}>
            <SelectName name="모듈명" />
            <TextField
              hiddenLabel
              size="small"
              onChange={(e) => {
                setValue({ ...value, module: e.target.value });
              }}
              inputProps={{ maxLength: 20 }}
              value={value.module || ""}
            />
          </SelectWrap>
        </SaveModal>
      )}
    </>
  );
};

export const RgsClctCmp = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={rgsClctCmp}
      body={{ clct_ids: selectedItems }}
      actionName="데이터 셋 등록완료"
      icon="IC_complete_w"
    />
  );
};

export const RgsClctDel = ({ selectedItems }) => {
  return (
    <DataDeleteConfirmationButton
      api={rgsClctDel}
      title="데이터 셋 삭제"
      selectedItems={selectedItems}
      icon="IC_delete_w"
      type="secondary"
    />
  );
};

export const RgsOrigUpload = ({ name = "원본 데이터 업로드", icon = "IC_upload_w", type }) => {
  return (
    <FloatingInsertButton
      listData={[
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ]}
      api={rgsOrigUl}
      actionName={name}
      multiple
      accept="png"
      icon={icon}
      type={type}
    />
  );
};

export const RgsDataUploadButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="relative">
        {open && <div className="fixed inset-0 z-[20]" onClick={() => setOpen(false)}></div>}
        <CommonButton
          name="데이터 업로드"
          onClick={() => setOpen((prev) => !prev)}
          cn={"min-w-[126px] !justify-start"}
          icon="IC_upload_w"
        />
        {open && (
          <ul className="top-100 absolute z-[20] w-full divide-y divide-[#2B63C1] border border-[#2B63C1] !bg-[white]">
            <li>
              <RgsOrigUpload name="원본" type="list_button" icon={null} />
            </li>
            <li>
              <FloatingButton
                api={rgsTailUl}
                actionName="Tailed"
                multiple
                accept="png"
                type="list_button"
              />
            </li>
            <li>
              <FloatingButton
                api={rgsInrangeUl}
                actionName="Inrange"
                multiple
                accept="png"
                type="list_button"
              />
            </li>
            <li>
              <FloatingButton
                api={rgsMapUl}
                actionName="참고"
                multiple
                accept="png"
                type="list_button"
              />
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export const RgsSegLabelUpload = () => {
  return (
    <FloatingButton
      api={rgsSegGtUl}
      actionName="라벨 png 데이터 업로드"
      accept="png"
      icon="IC_upload_w"
    />
  );
};

export const RgsDetLabelUpload = () => {
  return (
    <FloatingButton
      api={rgsDetGtUl}
      actionName="라벨 json 데이터 업로드"
      accept="json"
      icon="IC_upload_w"
    />
  );
};

export const RgsOrigDel = ({ contents, selectedItems }) => {
  return (
    <DelCheckButton
      api={rgsDelCheck}
      contents={contents}
      selectedItems={selectedItems}
      rgsDelApi={rgsDataDel}
      icon="IC_delete_w"
    />
  );
};
