import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const RGS_SET_TYPE = {
  PORT: "P",
  SAIL: "S",
  GENERATED: "G",
};

export const RGS_CLCT_COLS = {
  tab: [
    "data",
    "path",
    "port",
    "berth",
    "vessel",
    "date",
    "img_cnts",
    "rgs_rep",
    "crt_t",
    "clct_cmp_t",
    "module",
  ],
};

export const RGS_DATA_COLS = {
  cam: {
    [RGS_SET_TYPE.PORT]: {
      tab: ["sensor_name", "filename", "orig_ul_t"],
      hide: {
        table: {
          tab: [],
        },
        thumb: {
          tab: ["filename"],
        },
      },
    },
    [RGS_SET_TYPE.SAIL]: {
      tab: ["sensor_name", "filename", "orig_ul_t"],
      hide: {
        table: {
          tab: [],
        },
        thumb: {
          tab: ["filename"],
        },
      },
    },
    [RGS_SET_TYPE.GENERATED]: {
      tab: ["sensor_name", "filename", "orig_ul_t", "gen_seg_gt_ul_t", "gen_det_gt_ul_t"],
      hide: {
        table: {
          tab: [],
        },
        thumb: {
          tab: ["filename"],
        },
      },
    },
  },
  rad: {
    [RGS_SET_TYPE.PORT]: {
      tab: ["sensor_name", "filename", "orig_ul_t", "tail_ul_t", "inrange_ul_t", "map_ul_t"],
      hide: {
        table: {
          tab: [],
        },
        thumb: {
          tab: ["filename"],
        },
      },
    },
    [RGS_SET_TYPE.SAIL]: {
      tab: ["sensor_name", "filename", "orig_ul_t", "tail_ul_t", "inrange_ul_t", "map_ul_t"],
      hide: {
        table: {
          tab: [],
        },
        thumb: {
          tab: ["filename"],
        },
      },
    },
  },
};

export const makeImgTypeList = (dataSetType, sensorCode) =>
  IMG_TYPE_LIST.filter((x) =>
    (dataSetType === RGS_SET_TYPE.GENERATED
      ? ["orig_thumb", "gt_seg_thumb", "gt_det_thumb"]
      : sensorCode === "cam"
        ? ["orig_thumb"]
        : ["orig_thumb", "tail_thumb", "inrange_thumb", "map_thumb"]
    ).includes(x.thumb)
  );

export const RGS_CAM_TAB = [{ value: "1", ko: "Camera" }];
export const RGS_RAD_TAB = [{ value: "1", ko: "Radar" }];
