import { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import axios from "axios";
import { SelectItem, SelectName, SelectRadio, SelectWrap } from "../../components/atoms/SelectBox";
import { EmptyModal, SaveModal } from "../../components/molecules/CommonModals";
import { CommonButton } from "../../components/molecules/Buttons";
import { DownloadButton, FloatingSelectButton } from "../../components/organisms/actions";
import { useModals } from "../../lib/hooks";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { isDwlState } from "../../lib/stores";
import { lrnExportDwlLink, lrnFusionDwlLink, lrnMask } from "./Lrn.apis";

const CancelToken = axios.CancelToken;

export const LrnMask = ({ selectedItems, contents }) => {
  return (
    <FloatingSelectButton
      createMask
      ids={selectedItems}
      count={selectedItems.length}
      contents={contents}
      api={lrnMask}
      body={{ clct_ids: selectedItems }}
      actionName="mask 데이터 생성"
      icon="IC_add_w"
    />
  );
};

export const LrnInsertExport = ({ selectedItems }) => {
  const OPTIONS = [
    {
      name: "orig",
      ko: "원본 경로",
      type: "radio",
      sensor: "cam",
      gt: null,
    },
    {
      name: "gt_psd",
      ko: "라벨 psd 경로",
      type: "radio",
      sensor: "cam",
      gt: "seg",
    },
    {
      name: "mask",
      ko: "mask 경로",
      type: "radio",
      sensor: "cam",
      gt: "seg",
    },
    {
      name: "gt_json",
      ko: "라벨 json 경로",
      type: "radio",
      sensor: "cam",
      gt: "det",
    },
    {
      name: "orig_tail",
      ko: "Tailed 경로",
      type: "radio",
      sensor: "rad",
      gt: "seg",
    },
    {
      name: "mask",
      ko: "Mask 경로",
      type: "radio",
      sensor: "rad",
      gt: "seg",
    },
  ];

  const { urlParams, queryParams } = useDynamicRouting();
  const urlInfo = { ...urlParams, ...queryParams };

  const [downloadUrl, setDownloadUrl] = useState(null);
  const [successCnt, setSuccessCnt] = useState("");
  const [failType, setFailType] = useState("");
  const [required, setRequired] = useState(null);
  const [value, setValue] = useState({
    orig: "true",
    gt_psd: "true",
    gt_json: "true",
    mask: "true",
    orig_tail: "true",
  });
  const { modal, openModal, closeModal, modalStatus, setModalStatus } = useModals(null);

  const setIsDwl = useSetRecoilState(isDwlState);
  const source = useRef(null);

  const resetOpen = () => {
    // 0. 상태 리셋
    setValue({
      orig: "true",
      gt_psd: "true",
      gt_json: "true",
      mask: "true",
      orig_tail: "true",
    });
    setModalStatus(null);
    setFailType(null);
    setRequired(false);

    // 1. 모달 열기
    openModal();
  };

  const handleClick = async () => {
    setIsDwl(true);
    setModalStatus("loading");

    source.current = CancelToken.source();
    const body = { ...value, ids: selectedItems };

    try {
      // cam seg는 옵션 선택 가능
      // cam det, rad seg는 옵션 없이 전부 true
      const data = await lrnExportDwlLink(urlInfo, source, body);

      console.log(data);
      const url = `${process.env.REACT_APP_SERVER_BASE_URL}/lrn/${urlInfo.sensor_code}/${urlInfo.gt_code}/sets/export/${data.encrypt}/${data.type}`;

      // 3. 받아온 다운로드 링크를 담으면 버튼이 다운로드 버튼이 활성화됨
      setDownloadUrl(url);
      setSuccessCnt(data.success_cnt);
      setModalStatus("hasValue");
      source.current = null;
    } catch (err) {
      console.log(err);
      if (axios.isCancel(err)) {
        setModalStatus("hasError");
        setFailType("확인 취소");
      } else {
        console.log(err?.response);

        let err_detail = err?.response?.data?.detail || "";

        setModalStatus("hasError");
        setFailType(err_detail);
        source.current = null;
      }
    }
  };

  const handleClose = () => {
    if (source.current) {
      // 진행 중인 링크 생성 작업이있다면 모달을 닫을때 취소시킴
      source.current.cancel("요청이 취소되었습니다.");
      source.current = null;
    } else {
      closeModal();
    }
  };

  const handleDownloadClick = () => {
    if (modalStatus === "hasValue") {
      // 4. 다운로드 링크 존재 시 다운로드가 시작되므로 모달 닫음
      closeModal();
    } else if (modalStatus === "loading") {
      // 다운로드 링크 생성중일시
      handleClose();
    } else {
      // 2. 확인 버튼 클릭 시 다운로드 링크 api 호출
      handleClick();
    }
  };

  return (
    <>
      <CommonButton name="학습 리스트 다운로드" icon="IC_download_w" onClick={resetOpen} />

      {modal &&
        (selectedItems.length === 0 ? (
          <EmptyModal closeModal={closeModal} />
        ) : (
          <SaveModal
            closeModal={handleClose}
            title="학습 리스트 다운로드 정보 입력"
            count={selectedItems.length}
            modalStatus={modalStatus}
            handleClick={handleDownloadClick}
            failType={failType}
            lrnExport={{ downloadUrl, successCnt }}
          >
            {OPTIONS.map(
              (item, index) =>
                (!item.sensor || item.sensor === urlInfo.sensor_code) &&
                (!item.gt || item.gt === urlInfo.gt_code) && (
                  <SelectWrap key={index} required={required && !value[item.name]}>
                    <SelectName name={item.ko} />
                    {(urlInfo.gt_code === "seg" ? ["true", "false"] : ["true"]).map((x, i) => (
                      <SelectItem key={i}>
                        <SelectRadio
                          onChange={(e) => {
                            setValue({ ...value, [item.name]: x });
                          }}
                          checked={value[item.name] === x}
                          value={x}
                          disabled={urlInfo.sensor_code === "rad"}
                        />
                        <div className="pl-[8px]">{x}</div>
                      </SelectItem>
                    ))}
                  </SelectWrap>
                )
            )}
          </SaveModal>
        ))}
    </>
  );
};

export const DownloadFusion = ({ selectedItems }) => {
  return (
    <DownloadButton
      count={selectedItems.length}
      api={lrnFusionDwlLink}
      body={{ ids: selectedItems }}
      dlUrl={`/lrn/rad/seg/sets/fusion/export/`}
      actionName="센서융합 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};
