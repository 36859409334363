import { IMG_TYPE_LIST } from "../../lib/constants/constant";

export const LRN_CLCT_COLS = {
  cam_seg: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "cond_cnts",
      "label_vs",
      "lrn_cam_seg_cnt",
      "gt_tab_cnts",
      "latest_check_date",
      "latest_check_ul_t",
      "mask_created",
      "module",
    ],
    hide: ["label_vs", "module"],
  },
  cam_det: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "cond_cnts",
      "ids_num",
      "lrn_person_obj_num",
      "lrn_vessel_obj_num",
      "lrn_buoy_obj_num",
      "lrn_smoke_obj_num",
      "lrn_fire_obj_num",
      "label_vs",
      "lrn_det_cnt",
      "gt_tab_cnts",
      "latest_check_date",
      "latest_check_ul_t",
      "module",
    ],
    hide: ["label_vs", "module"],
  },
  rad_seg: {
    tab: [
      "data",
      "path",
      "port",
      "berth",
      "vessel",
      "date",
      "label_vs",
      "lrn_rad_seg_cnt",
      "gt_tab_cnts",
      "latest_check_date",
      "latest_check_ul_t",
      "mask_created",
      "module",
    ],
    hide: ["label_vs"],
  },
};

export const LRN_DATA_COLS = {
  cam_seg: {
    tab: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    hide: {
      table: [],
      thumb: [
        "rework_cnt",
        "filename",
        "snow",
        "rain",
        "fog",
        "light",
        "backlight",
        "night",
        "smoke",
      ],
    },
  },
  cam_det: {
    tab: [
      "sensor_name",
      "clct_t",
      "rework_cnt",
      "filename",
      "snow",
      "rain",
      "fog",
      "light",
      "backlight",
      "night",
      "smoke",
    ],
    hide: {
      table: [],
      thumb: [
        "rework_cnt",
        "filename",
        "snow",
        "rain",
        "fog",
        "light",
        "backlight",
        "night",
        "smoke",
      ],
    },
  },
  rad_seg: {
    tab: ["sensor_name", "clct_t", "filename"],
    hide: {
      table: [],
      thumb: ["filename"],
    },
  },
};

export const makeImgTypeList = (sensor_code, gt_code) =>
  IMG_TYPE_LIST.filter((x) =>
    (sensor_code === "rad"
      ? [
          "auto_thumb",
          "tail_thumb",
          "inrange_thumb",
          "gt_thumb",
          "map_thumb",
          "inrange_blend_thumb",
          "gt_blend_thumb",
        ]
      : gt_code === "det"
        ? ["auto_thumb", "orig_thumb", "blend_thumb"]
        : ["auto_thumb", "orig_thumb", "blend_thumb", "gt_thumb"]
    ).includes(x.thumb)
  );
