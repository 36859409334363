import { axiosInstance } from "../../components/templates/RootErrorBoundary";

// 작업 화면 탭별 데이터 수 조회
export const workClctCnt = async ({ sensor_code, gt_code, t }) => {
  const { data } = await axiosInstance.get(`/work/${sensor_code}/${gt_code}/sets/cnt/`, {
    params: { t },
  });

  return data;
};

// 작업 화면 데이터셋 리스트 조회
export const workClctList = async ({ sensor_code, gt_code, tab, ...params }) => {
  const { data } = await axiosInstance.get(`/work/${sensor_code}/${gt_code}/sets/${tab}/`, {
    params,
  });

  return data;
};

// 작업 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const workClctFilter = async ({ sensor_code, gt_code, col, tab }, params) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/sets/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 작업 화면 작업회수 요청
export const workReturnReq = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/cncl/req/`,
    body
  );

  return data;
};

// 작업 화면 작업 시작
export const workStart = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/start/`,
    body
  );

  return data;
};

// 이미지 미리보기
export const workPreview = async ({ sensor_code, gt_code, id, img_type }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/datas/${id}/pre/${img_type}/`
  );

  return data;
};

// 작업 화면 탭별 데이터 수 조회
export const workCnt = async ({ sensor_code, gt_code, set_id, t }) => {
  const { data } = await axiosInstance.get(`/work/${sensor_code}/${gt_code}/datas/${set_id}/cnt/`, {
    params: { t },
  });

  return data;
};

// 원본 다운로드 시 작업 셋을 선택하기 위한 전체 작업 셋 조회
export const workDistWorkSetList = async ({ sensor_code, gt_code, set_id }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/sets/values/`
  );

  return data;
};

// 작업 화면 전체 원본 다운로드 링크 조회
export const workOrigAllDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 원본 다운로드 링크 조회
export const workOrigDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/link/opt/`,
    body
  );

  return data;
};

// 작업 화면 psd 전체 원본 다운로드 링크 조회
export const workOrigPsdAllDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/re/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 psd 원본 다운로드 링크 조회
export const workOrigPsdDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/re/dwl/link/opt/`,
    body
  );

  return data;
};

//
export const workGtDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/gt/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 (필터링시) 해당 컬럼 값 리스트 조회
export const workFilter = async ({ sensor_code, gt_code, set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/cols/${col}/`,
    { params }
  );

  return data;
};

// 작업 화면 데이터 리스트 id 조회
export const workIds = async ({ sensor_code, gt_code, set_id, tab, view, page, ...params }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/ids/`,
    { params }
  );

  return data;
};

// 작업 화면 데이터 리스트 조회
export const workList = async ({ sensor_code, gt_code, set_id, tab, view, ...params }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/${tab}/${view}/`,
    { params }
  );

  return data;
};

// sensor_code별 gt_code에 해당하는 데이터 작업 화면 검수 요청
export const workCheckReq = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/check/req/`,
    body
  );

  return data;
};

// cam, rad 작업 화면 에러 검사 요청
export const workInspect = async ({ sensor_code, gt_code, set_id }, body, source) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/insp/`,
    body,
    {
      cancelToken: source.current.token,
    }
  );

  return data;
};

// 카메라 세그멘테이션 작업 화면 에러검사 상세정보 리스트 조회
export const workInspectDetail = async ({ gt_code, id, t }) => {
  const { data } = await axiosInstance.get(`/work/cam/${gt_code}/datas/${id}/insp/errs/`, {
    params: { t },
  });

  return data;
};

// 카메라 세그멘테이션 작업 화면 에러파일 다운로드 링크 조회
export const workInspectDwlLink = async ({ gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/cam/${gt_code}/datas/${set_id}/insp/dwl/link/`,
    body
  );

  return data;
};

// cam, rad 작업 화면 gt 파일 업로드
export const workGtUl = async (
  { sensor_code, gt_code, set_id },
  body,
  { onUploadProgress },
  source
) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/datas/${set_id}/gt/ul/`,
    body,
    {
      onUploadProgress,
      cancelToken: source.current.token,
    }
  );

  return data;
};

// 레이다 작업 화면 가공용 다운로드 링크 조회
export const workInrangeDwlLink = async ({ gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/rad/${gt_code}/datas/${set_id}/inrange/dwl/link/opt/`,
    body
  );

  return data;
};

// 레이다 전체 작업 화면 가공용 다운로드 링크 조회
export const workInrangeAllDwlLink = async ({ gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/rad/${gt_code}/datas/${set_id}/inrange/dwl/link/`,
    body
  );

  return data;
};

// 레이다 작업 화면 map 다운로드 링크 조회
export const workMapDwlLink = async ({ gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/rad/${gt_code}/datas/${set_id}/map/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 가이드파일 다운로드 링크 조회
export const workClctGuideDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/clct_guides/${set_id}/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 가이드파일 다운로드 링크 조회
export const workGuideDwlLink = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/guides/${set_id}/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 영상 리스트 조회
export const workVideoList = async ({ gt_code, sensor_code, set_id, t }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/clct_guides/${set_id}/videos/`,
    {
      params: { t },
    }
  );

  return data;
};

// 작업 화면 가이드파일 다운로드 링크 조회
export const workVideoDwlLink = async ({ gt_code, sensor_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/clct_guides/${set_id}/videos/dwl/link/`,
    body
  );

  return data;
};

// 작업 화면 QnA 카드 삭제
export const workQnaDelete = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/qnas/${set_id}/del/`,
    body
  );

  return data;
};

// QnA 이미지 미리보기
export const workQnaPreview = async ({ sensor_code, gt_code, id }) => {
  const { data } = await axiosInstance.get(`/work/${sensor_code}/${gt_code}/qnas/${id}/pre/`);

  return data;
};

// QnA 신규 등록시 작업셋 리스트 조회
export const workWorkSetList = async ({ sensor_code, gt_code, set_id }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/qnas/${set_id}/values/`
  );

  return data;
};

// 작업 화면 QnA 신규 등록
export const workQnaCreate = async ({ sensor_code, gt_code, set_id }, body) => {
  const { data } = await axiosInstance.post(
    `/work/${sensor_code}/${gt_code}/qnas/${set_id}/`,
    body
  );

  return data;
};

// 작업 화면 ref 리스트 id 조회
export const workRefIds = async ({ sensor_code, gt_code, set_id, tab, page, ...params }) => {
  const { data } = await axiosInstance.get(`/work/${sensor_code}/${gt_code}/qnas/${set_id}/ids/`, {
    params,
  });

  return data;
};

// 작업 화면 ref 리스트 조회
export const workRefList = async ({ sensor_code, gt_code, set_id, tab, ...params }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/${
      tab === "1" ? "clct_guides" : tab === "2" ? "guides" : "qnas"
    }/${set_id}/`,
    { params }
  );

  return data;
};

// 작업 화면 ref 탭별 데이터 수 조회
export const workRefCnt = async ({ sensor_code, gt_code, set_id, t }) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/guides/${set_id}/cnt/`,
    { params: { t } }
  );

  return data;
};

// 작업 화면 Ref (필터링시) 해당 컬럼 값 리스트 조회
export const workRefFilter = async ({ sensor_code, gt_code, set_id, tab, col }, params) => {
  const { data } = await axiosInstance.get(
    `/work/${sensor_code}/${gt_code}/${
      tab === "1" ? "clct_guides" : "guides"
    }/${set_id}/cols/${col}/`,
    { params }
  );

  return data;
};
