import { useState, useRef } from "react";
import {
  ConfirmButton,
  BatchDownloadButton,
  DownloadButton,
  FloatingSelectButton,
  FloatingButton,
  QnaButton,
  SelectInsertButton,
  TableButton,
} from "../../components/organisms/actions";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { REF_VIDEO_COL } from "../../lib/constants/constant";
import { workVideoListQuery } from "./Work.stores";
import {
  workCheckReq,
  workClctGuideDwlLink,
  workGtDwlLink,
  workGtUl,
  workGuideDwlLink,
  workInrangeDwlLink,
  workInspect,
  workInspectDwlLink,
  workMapDwlLink,
  workDistWorkSetList,
  workOrigAllDwlLink,
  workOrigDwlLink,
  workOrigPsdAllDwlLink,
  workOrigPsdDwlLink,
  workInrangeAllDwlLink,
  workQnaCreate,
  workQnaDelete,
  workReturnReq,
  workStart,
  workVideoDwlLink,
} from "./Work.apis";

export const SetReturnReq = ({ selectedItems }) => {
  const data = ["데이터 손상", "계약 중단", "작업 포기", "기타"];

  return (
    <SelectInsertButton
      body={{ ids: selectedItems }}
      count={selectedItems.length}
      api={workReturnReq}
      actionName="작업회수 요청"
      icon="IC_remove_w"
      type="secondary"
      selectList={data}
      selectName={{ key: "cncl_cuz", ko: "작업회수 요청 사유" }}
    />
  );
};

//
export const DownloadOrigAll = () => {
  const { urlParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;

  return (
    <BatchDownloadButton
      downloadLinkApi={workOrigAllDwlLink}
      worksetListApi={workDistWorkSetList}
      dlUrl={`/work/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/`}
      actionName="전체 원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadOrigin = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workOrigDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/${sensor_code}/${gt_code}/datas/${set_id}/orig/dwl/`}
      actionName="원본 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadInrangeAll = () => {
  const { urlParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;

  return (
    <BatchDownloadButton
      downloadLinkApi={workInrangeAllDwlLink}
      worksetListApi={workDistWorkSetList}
      dlUrl={`/work/${sensor_code}/${gt_code}/datas/${set_id}/inrange/dwl/`}
      actionName="전체 Inrange 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtPsdAll = () => {
  const {
    urlParams: { gt_code, set_id },
  } = useDynamicRouting();

  return (
    <BatchDownloadButton
      api={workOrigPsdAllDwlLink}
      downloadLinkApi={workOrigPsdAllDwlLink}
      worksetListApi={workDistWorkSetList}
      dlUrl={`/work/cam/${gt_code}/datas/${set_id}/re/dwl/`}
      actionName="전체 psd 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGtPsd = ({ selectedItems }) => {
  const {
    urlParams: { gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workOrigPsdDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/cam/${gt_code}/datas/${set_id}/re/dwl/`}
      actionName="psd 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGt = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  const ActionName = {
    cam: {
      seg: "psd",
      det: "JSON",
    },
    rad: {
      seg: "png",
    },
  };

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workGtDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/${sensor_code}/${gt_code}/datas/${set_id}/gt/dwl/`}
      actionName={`${ActionName[sensor_code][gt_code]} 데이터 다운로드`}
      icon="IC_download_w"
    />
  );
};

export const DownloadInrange = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workInrangeDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/rad/seg/datas/${set_id}/inrange/dwl/`}
      actionName="Inrange 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadMap = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workMapDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/rad/seg/datas/${set_id}/map/dwl/`}
      actionName="참고 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const SetWorkStart = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={workStart}
      hasPath
      body={{ ids: selectedItems }}
      actionName="작업시작"
      icon="IC_complete_w"
    />
  );
};

export const GtUpload = () => {
  const {
    urlParams: { sensor_code, gt_code },
  } = useDynamicRouting();

  const ActionName = {
    cam: {
      seg: "psd",
      det: "JSON",
    },
    rad: {
      seg: "png",
    },
  };

  const Accept = {
    cam: {
      seg: "psd",
      det: "json",
    },
    rad: {
      seg: "png",
    },
  };

  return (
    <FloatingButton
      api={workGtUl}
      actionName={`${ActionName[sensor_code][gt_code]} 데이터 업로드`}
      accept={Accept[sensor_code][gt_code]}
      icon="IC_upload_w"
    />
  );
};

export const Inspect = ({ contents, selectedItems }) => {
  return (
    <FloatingSelectButton
      ids={selectedItems}
      count={selectedItems.length}
      contents={contents}
      api={workInspect}
      actionName="에러검사"
      icon="IC_complete_w"
    />
  );
};

export const DownloadErr = ({ selectedItems }) => {
  const {
    urlParams: { set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workInspectDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/cam/seg/datas/${set_id}/insp/dwl/`}
      actionName="에러 참고 데이터 다운로드"
      icon="IC_download_w"
    />
  );
};

export const SetCheckReq = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={workCheckReq}
      hasPath
      body={{ ids: selectedItems }}
      actionName="검수요청"
      icon="IC_add_w"
    />
  );
};

export const WorkVideoList = () => {
  const [selectedObj, setSelectedObj] = useState({
    selectedItems: [],
    lastSelectedItem: null,
  });
  const modalRef = useRef(null);

  return (
    <TableButton
      ref={modalRef}
      cols={REF_VIDEO_COL}
      select
      listQuery={workVideoListQuery}
      actionName="참고 영상 리스트"
      listName="참고 영상"
      icon="IC_table_b"
      type="unfill"
      selectedObj={selectedObj}
      setSelectedObj={setSelectedObj}
    >
      <DownloadVideo selectedItems={selectedObj.selectedItems} />
    </TableButton>
  );
};

export const DownloadVideo = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workVideoDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/${sensor_code}/${gt_code}/clct_guides/${set_id}/videos/dwl/`}
      actionName="참고 영상 다운로드"
      icon="IC_download_w"
      type="modal_btn"
    />
  );
};

export const DownloadClctGuide = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workClctGuideDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/${sensor_code}/${gt_code}/clct_guides/${set_id}/dwl/`}
      actionName="작업 가이드 다운로드"
      icon="IC_download_w"
    />
  );
};

export const DownloadGuide = ({ selectedItems }) => {
  const {
    urlParams: { sensor_code, gt_code, set_id },
  } = useDynamicRouting();

  return (
    <DownloadButton
      count={selectedItems.length}
      api={workGuideDwlLink}
      hasPath
      body={{ ids: selectedItems }}
      dlUrl={`/work/${sensor_code}/${gt_code}/guides/${set_id}/dwl/`}
      actionName="작업 가이드 다운로드"
      icon="IC_download_w"
    />
  );
};

export const WorkQnaCreate = () => {
  return (
    <QnaButton
      type="question"
      api={workQnaCreate}
      title="질문등록"
      placeholder="질문을 입력해 주세요."
      icon="IC_add_w"
    />
  );
};

export const WorkQnaDelete = ({ selectedItems }) => {
  return (
    <ConfirmButton
      count={selectedItems.length}
      api={workQnaDelete}
      hasPath
      body={{ ids: selectedItems }}
      actionName="질문삭제"
      icon="IC_delete_w"
      type="secondary"
    />
  );
};
