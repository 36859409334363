import { useRecoilValueLoadable } from "recoil";
import CommonTemplate from "../../components/templates/CommonTemplate";
import { SelectCnt } from "../../components/atoms/CommonAtoms";
import {
  GT_TAB_CAMDET_CHECK,
  GT_TAB_CAMSEG_CHECK,
  GT_TAB_RADSEG_CHECK,
  REF_TAB_LINK,
  DATA_STATUS_CODE,
} from "../../lib/constants/constant";
import { useDynamicRouting } from "../../lib/context/useDynamicRouting";
import { makeImgTypeList, CHECK_DATA_COLS } from "./Check.constants";
import { checkCntQuery, checkIdsQuery, checkListQuery, checkPreviewQuery } from "./Check.stores";
import { checkFilter } from "./Check.apis";
import {
  CheckAdd,
  CheckCancel,
  CheckCancelDirect,
  CheckCmp,
  CheckDel,
  CheckDistReset,
  CheckGtReUpload,
  CheckInfo,
  CheckModifyChecker,
  CheckModifyCheckerAll,
  CheckModifyCheckerReq,
  CheckOrigVideoList,
  CheckPay,
  CheckRemove,
  CheckReset,
  CheckWorkSetList,
  CheckWorkStartReset,
  DownloadBlend,
  DownloadBlendAll,
  DownloadGtLabel,
  DownloadGtLabelAll,
  DownloadGtPsd,
  DownloadGtPsdAll,
  DownloadGtJsonAll,
  DownloadGtJson,
  DownloadOrig,
  DownloadOrigAll,
  DownloadInrangeAll,
  DownloadInrange,
  Inspect,
  SetPass,
  SetReturn,
  SetRework,
} from "./CheckActions";

const CheckDataPage = () => {
  const { urlParams, queryParams } = useDynamicRouting();
  const { sensor_code, gt_code, set_id } = urlParams;
  const { tab, t } = queryParams;

  const { state: state_cnt, contents: contents_cnt } = useRecoilValueLoadable(
    checkCntQuery({ ...urlParams, tab, t })
  );
  const { state: state_ids, contents: contents_ids } = useRecoilValueLoadable(
    checkIdsQuery({ sensor_code, gt_code, set_id, ...queryParams })
  );

  return (
    <CommonTemplate
      listQuery={checkListQuery}
      state_cnt={state_cnt}
      contents_cnt={contents_cnt}
      contents_ids={state_ids === "hasValue" ? contents_ids.ids : []}
      filterApi={checkFilter}
      previewQuery={checkPreviewQuery}
      fullColumnData={CHECK_DATA_COLS[`${sensor_code}_${gt_code}`].tab1}
      columnData={CHECK_DATA_COLS[`${sensor_code}_${gt_code}`][`tab${tab}`]}
      hideColumn={CHECK_DATA_COLS[`${sensor_code}_${gt_code}`].hide.table[`tab${tab}`]}
      hideThumbColumn={CHECK_DATA_COLS[`${sensor_code}_${gt_code}`].hide.thumb[`tab${tab}`]}
      tabData={
        sensor_code === "rad"
          ? GT_TAB_RADSEG_CHECK
          : gt_code === "det"
            ? GT_TAB_CAMDET_CHECK
            : GT_TAB_CAMSEG_CHECK
      }
      refTabData={REF_TAB_LINK.ref}
      viewData
      imgTypeData={makeImgTypeList(sensor_code, gt_code)}
      statusCodeData={DATA_STATUS_CODE}
      tagType="dist"
      actions={renderUtilButtons(sensor_code, gt_code, tab)}
    />
  );
};

const renderUtilButtons = (() => {
  const TABS = {
    전체: 1,
    배분대기: 2,
    작업대기: 4,
    작업중: 5,
    검수대기: 6,
    검수반려: 7,
    검수통과: 8,
    작업회수: 10,
    검수자변경: 11,
  };

  return (sensor_code, gt_code, tabNumber) =>
    ({ selectedItems, contents }) => {
      const buttonsForAllContents = {
        cam: {
          seg: {
            [TABS.전체]: (
              <>
                <CheckOrigVideoList />
                <DownloadOrigAll count={contents.count} />
              </>
            ),
            [TABS.검수대기]: <DownloadGtPsdAll count={contents.count} />,
            [TABS.검수통과]: (
              <>
                <DownloadGtPsdAll count={contents.count} />
                <CheckGtReUpload />
              </>
            ),
            [TABS.검수자변경]: <CheckModifyCheckerAll count={contents.count} />,
          },
          det: {
            [TABS.전체]: <DownloadOrigAll count={contents.count} />,
            [TABS.검수대기]: (
              <>
                <DownloadGtJsonAll count={contents.count} />
                <DownloadBlendAll count={contents.count} />
              </>
            ),
            [TABS.검수통과]: (
              <>
                <DownloadGtJsonAll count={contents.count} />
                <DownloadBlendAll count={contents.count} />
                <CheckGtReUpload />
              </>
            ),
            [TABS.검수자변경]: <CheckModifyCheckerAll count={contents.count} />,
          },
        },
        rad: {
          seg: {
            [TABS.전체]: (
              <>
                <CheckOrigVideoList />
                <DownloadInrangeAll count={contents.count} />
              </>
            ),
            [TABS.검수대기]: <DownloadGtLabelAll count={contents.count} />,
            [TABS.검수통과]: (
              <>
                <DownloadGtLabelAll count={contents.count} />
                <CheckGtReUpload />
              </>
            ),
            [TABS.검수자변경]: <CheckModifyCheckerAll count={contents.count} />,
          },
        },
      };
      const buttonsForSelectedContents = {
        cam: {
          seg: {
            [TABS.전체]: (
              <>
                <DownloadOrig selectedItems={selectedItems} />
                <CheckCancelDirect selectedItems={selectedItems} />
              </>
            ),
            [TABS.배분대기]: (
              <>
                <CheckInfo selectedItems={selectedItems} />
                <CheckAdd selectedItems={selectedItems} />
                <CheckRemove selectedItems={selectedItems} />
                <CheckDel contents={contents.results} selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업대기]: (
              <>
                <CheckDistReset selectedItems={selectedItems} />
                <CheckModifyCheckerReq selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <CheckWorkStartReset selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수대기]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <DownloadGtPsd selectedItems={selectedItems} />
                <SetPass selectedItems={selectedItems} />
                <SetReturn selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수반려]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <CheckReset selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수통과]: (
              <>
                <Inspect selectedItems={selectedItems} contents={contents?.results} />
                <DownloadGtPsd selectedItems={selectedItems} />
                <CheckPay selectedItems={selectedItems} />
                <CheckCmp selectedItems={selectedItems} />
                <CheckReset selectedItems={selectedItems} />
                <SetRework selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업회수]: <CheckCancel selectedItems={selectedItems} />,
            [TABS.검수자변경]: <CheckModifyChecker selectedItems={selectedItems} />,
          },
          det: {
            [TABS.전체]: (
              <>
                <DownloadOrig selectedItems={selectedItems} />
                <CheckCancelDirect selectedItems={selectedItems} />
              </>
            ),
            [TABS.배분대기]: (
              <>
                <CheckInfo selectedItems={selectedItems} />
                <CheckAdd selectedItems={selectedItems} />
                <CheckRemove selectedItems={selectedItems} />
                <CheckDel contents={contents.results} selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업대기]: (
              <>
                <CheckDistReset selectedItems={selectedItems} />
                <CheckModifyCheckerReq selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <CheckWorkStartReset selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수대기]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <DownloadGtJson selectedItems={selectedItems} />
                <DownloadBlend selectedItems={selectedItems} />
                <SetPass selectedItems={selectedItems} />
                <SetReturn selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수반려]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <CheckReset selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수통과]: (
              <>
                <DownloadGtJson selectedItems={selectedItems} />
                <DownloadBlend selectedItems={selectedItems} />
                <CheckPay selectedItems={selectedItems} />
                <CheckCmp selectedItems={selectedItems} />
                <CheckReset selectedItems={selectedItems} />
                <SetRework selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업회수]: <CheckCancel selectedItems={selectedItems} />,
            [TABS.검수자변경]: <CheckModifyChecker selectedItems={selectedItems} />,
          },
        },
        rad: {
          seg: {
            [TABS.전체]: (
              <>
                <DownloadInrange selectedItems={selectedItems} />
                <CheckCancelDirect selectedItems={selectedItems} />
              </>
            ),
            [TABS.배분대기]: (
              <>
                <CheckInfo selectedItems={selectedItems} />
                <CheckAdd selectedItems={selectedItems} />
                <CheckRemove selectedItems={selectedItems} />
                <CheckDel contents={contents.results} selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업대기]: (
              <>
                <CheckDistReset selectedItems={selectedItems} />
                <CheckModifyCheckerReq selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업중]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <CheckWorkStartReset selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수대기]: (
              <>
                <DownloadGtLabel selectedItems={selectedItems} />
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <SetPass selectedItems={selectedItems} />
                <SetReturn selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수반려]: (
              <>
                <CheckModifyCheckerReq selectedItems={selectedItems} />
                <CheckReset selectedItems={selectedItems} />
              </>
            ),
            [TABS.검수통과]: (
              <>
                <DownloadGtLabel selectedItems={selectedItems} />
                <CheckPay selectedItems={selectedItems} />
                <CheckCmp selectedItems={selectedItems} />
                <CheckReset selectedItems={selectedItems} />
                <SetRework selectedItems={selectedItems} />
              </>
            ),
            [TABS.작업회수]: <CheckCancel selectedItems={selectedItems} />,
            [TABS.검수자변경]: <CheckModifyChecker selectedItems={selectedItems} />,
          },
        },
      };
      return (
        <>
          <CheckWorkSetList tab={tabNumber} />
          {buttonsForAllContents[sensor_code]?.[gt_code]?.[tabNumber]}
          <SelectCnt count={selectedItems.length} />
          {buttonsForSelectedContents[sensor_code]?.[gt_code]?.[tabNumber]}
        </>
      );
    };
})();

export default CheckDataPage;
